<template lang='pug'>

        v-btn.heart(@click='changeFollow' icon v-if="follow" key="follow")
            v-icon.pb-0.flip-scale-2-ver-right(size="30" color='#ffc107' ) mdi-star
        v-btn(@click='changeFollow' icon v-else key="unfollow")
            v-icon.pb-0(size="30" color='rgb(255 255 255)' ) mdi-dark mdi-star-outline
</template>
<script>

import FollowDataService from '@/services/FollowDataService';
export default {
    components: {
    },
    props: {
        follow: {
            type: Boolean,
            required: true,
        },
        page: {
            type: String,
            required: true,
        },
        target_id: {
            type: String,
            required: true,
        }
    },
    data() {
        return {

        }
    },
    created(){
    },
    methods: {
        changeFollow(){
            let data = {
                "add_list":[],
                "delete_list":[],
                "class_name":this.page
            }
            if(this.follow){
                data['delete_list']=[this.target_id];
            }else{
                data['add_list']=[this.target_id];
            }
            FollowDataService.bulkCreateDeleteFollow(data)
            .then(()=>{
                if(this.follow){
                    this.$emit('emitChangeFollow',false);
                }else{
                    this.$emit('emitChangeFollow',true);
                }
            })
        },
    },
}
</script>
