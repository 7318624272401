<template lang="pug">
	v-overlay(:z-index='zIndex' opacity='.6')
		h3.text-center.mb-5.white--text {{message}}
		v-progress-linear(indeterminate color='white' rounded height="6")
</template>

<script>
	import Vue from 'vue';

	export default Vue.extend({
        props: {
            zIndex: {
                type: Number,
                default: 99,
            },
            message: {
                type: String,
                required: true,
            },
        },
	});
</script>