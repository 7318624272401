<template lang='pug'>
    v-icon(class='justify-start' v-if="follow" size="20" color='#ffc107' ) mdi-star
    v-icon(v-else).pl-5
</template>
<script>

export default {
    components: {
    },
    props: {
        follow: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {

        }
    },
    created(){
    },
    methods: {
    },
}
</script>