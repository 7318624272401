<template lang="pug">
    div
        v-card.d-inline-block.ma-3(max-width='300' v-for='item in newsPaper' :key='item.id' :to='item.href' flat)
            img(src='styles/images/new1.png' height='180')
            v-card-title.pa-0  {{ item.title }}
            v-card-text.text--primary.px-0.pb-0  {{ item.content }}
</template>

<script>
	import Vue from 'vue';

	export default Vue.extend({
        props: {
        },
        data() {
            return {        
                newsPaper: [{
                        id: 1,
                        title: '我們真的需要超過 12.9 吋的 iPad 嗎？',
                        content: '蘋果產品預測準確率頗高的彭博社作者 Mark Gurman 最近發文，表示蘋果正在探索更大尺寸的 iPad，也就是說未來新 iPad 可能會超越現在 iPad Pro 的 12.9 吋。14 吋，又或是 16 吋？',
                        pic: 'styles/images/new1.png',
                        href: '#',
                    },
                    {
                        id: 2,
                        title: 'WFH 成永久趨勢？曼哈頓商辦空置率創史上新高',
                        content: '蘋果產品預測準確率頗高的彭博社作者 Mark Gurman 最近發文，表示蘋果正在探索更大尺寸的 iPad，也就是說未來新 iPad 可能會超越現在 iPad Pro 的 12.9 吋。14 吋，又或是 16 吋？',
                        pic: 'styles/images/new2.png',
                        href: '#',
                    }
                ],
            }
        },
        methods: {
        }
	});
</script>
