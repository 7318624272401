<template lang='pug'>
    div
        .d-flex.align-center.justify-space-between.mt-2.mb-4
            h3.mt-2.mb-4.d-flex.align-center
                .cube-icon.small.bg-og.mr-2
                    v-icon(size='19' color='white') icon-chatmoney
                span {{$t('OPPTYCARD.TITLE')}}
        v-row.sheet-box(v-if='openOppties.length == 0')
            v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                    h5.ma-0.t-black {{$t('OPPTYCARD.NO_OPPTY')}}
        v-row.deal-box(v-else :class="showOpptyMore ? 'show-more':''")
            v-col.px-1.py-3(cols='4' v-for='Item, index in openOppties' :key='index')
                v-card.deals-item.pa-0( flat outlined @click='showToDealDialog(Item)')
                    v-card-title.d-flex.align-center.justify-space-between.px-4.pt-4.pb-0
                        v-row.ellipsis
                            v-col.d-flex.align-center.px-0(cols='10')
                                h5.ellipsis(:title='Item.name') {{ Item.name }}
                            v-tooltip(bottom)
                                template(v-slot:activator='{ on, attrs }')
                                    v-col(cols='2').d-flex.justify-end.align-center.px-0
                                        v-btn.border-none(width='14' height='14' :class='`customcolor`' :color='Item.stage_light_color.color' v-bind='attrs' v-on='on' fab depressed)
                                span {{ Item.stage_light_color.tip }}
                    v-card-text.pa-0
                        v-row.d-flex.text-left(no-gutters='no-gutters')
                            v-col(cols='12')
                                p.mt-1.mb-2.px-4 {{$t('OPPTYCARD.EXPECTED_CLOSE_DATE')}} : {{ Item.expected_close_date }} 
                        v-row.d-flex.align-center(no-gutters='no-gutters')
                            v-col.pl-4(cols='9')
                                span.ellipsis $ {{ getFormatAmount(Item.amount) }}
                            v-col.text-right.pr-3(cols='3')
                                v-avatar(color='aliceBlue' size='28')
                                    span(v-if="Item.owner.usersetting.photo === null") {{ Item.owner.last_name }}
                                    img(v-else :src='Item.owner.usersetting.photo' :alt='Item.owner.usersetting.photo')
                        v-progress-linear.mt-2( :value='(Item.stage_progress)*100' :color='Item.current_stage.stage_color')
        v-row.justify-end
            v-btn.pa-0(v-if='openOppties.length > 3' @click='moreOppty()' color='primary' text) {{ btnText }}
        v-dialog(v-model='toDealDialog' max-width='1200' v-if='toDealDialog')
            to-deal(@emitToDealDialog='onEmitToDealDialog' :oppty-data='opptyData')
</template>
<script>

import toDeal from '@/components/Dialog/Deal/toDeal';

export default {
    props: {
        openOppties: {
            type: Array,
            require:true,
        }
    },
    components: {
        toDeal,
    },
    data() {
        return {
            // dialog
            opptyData: {},
            toDealDialog: false,
            // other
            showOpptyMore: false,
            btnText: 'MORE',
        }
    },
    methods: {
        // dialog
        async showToDealDialog(item){
            await (this.opptyData = item);
            await (this.toDealDialog = true);
        },
        onEmitToDealDialog() {
            this.toDealDialog = false;
        },
        // other
        moreOppty() {
            if (this.showOpptyMore == true) {
                this.showOpptyMore = false;
                this.btnText = 'MORE';
            } else {
                this.showOpptyMore = true;
                this.btnText = 'LESS';
            }
        },
    },
}
</script>