<template lang="pug">
// 匯出CSV
v-card
  v-card-title.d-flex.justify-center {{$t('IMPORT_EXPORT.TITLE_2')}}
  v-card-text
    v-form(v-model="valid", ref="form")
      v-select.no-border(
        :label=`$t('IMPORT_EXPORT.PAGE')`,
        v-model="page",
        prepend-icon="fa-chart-pie",
        :items="pageList",
        item-text="name",
        item-value="key",
        flat,
        :rules='formRule.fieldRules',
      )
      v-select.no-border(
        v-if='page=="Case"|page=="Opportunity"'
        :label=`$t('IMPORT_EXPORT.STAGE')`,
        v-model="stage",
        prepend-icon="icon-step",
        :items="stageTypeList",
        item-text="stage_type_name",
        item-value="id",
        flat,
        :rules='formRule.fieldRules', 
      )
      v-row(no-gutters)
        v-col(:cols="!canEdit_filter(filter_data) ? 12 : 11").d-flex.justify-start 
          v-select.no-border(
            v-if='page!="KnowledgeArticle" && page!="Activity"'
            :label=`$t('SETTING.FILTERSETTING')`,
            v-model="filter",
            prepend-icon="fa-filter",
            :items="filter_list",
            item-text="name",
            item-value="id",
            flat,
          )
        v-col(cols="1" v-if='canEdit_filter(filter_data)').d-flex.justify-end
          v-btn.mt-3(@click='showEditFilterDialog(filter_data)' :ripple='false' height='38' width='36' min-width='36' color='white' depressed tile dark)
            v-icon(size='16' color='black') icon-edit
      v-text-field.no-border(
        v-if='page=="Contact" || page=="Organization" || page=="Activity" || (((page=="Case" || page=="Opportunity") && tab=="list") )'
        :label=`$t('IMPORT_EXPORT.SEARCH')`,
        v-model="search_str",
        prepend-icon="icon-search",
        flat,
      )
      v-select.no-border(
        v-if='page=="Case" || page=="Opportunity"'
        :label=`page=='Case' ? $t('CASE.OWNER') : $t('DEAL_DETAIL.OWNER') `,
        v-model="selectOwner",
        prepend-icon="icon-user",
        :items="page=='Case' ? ownerCaseList : ownerOpptyList",
        item-text="full_name",
        item-value="id",
        flat,
      )
      v-checkbox.no-border.mt-0(
        v-if=' page=="Opportunity"'
        :label="$t('IMPORT_EXPORT.OPPTY_PRODUCT')"
        v-model='opptyProduct'
        height='36')

    //- Activity
    div(v-if='page=="Activity"')
        v-combobox.no-border(prepend-icon="icon-user",:label="$t('ACTIVITY.OWNER')" v-model='activitySelectOwner' :placeholder="activitySelectOwner.length == 0? $t('FILTERSETTING.NO_FILTER'): ''" :items='activityOwnerList' item-value='id' item-text='full_name' max-width='100' height='36' flat @change='' multiple)
            template(v-slot:prepend-item)
                v-list-item(style='font-size: 0.8125rem;' border-0 @click="activitySelectOwner = []")
                    v-icon(v-if='activitySelectOwner.length > 0' class='v-icon notranslate far fa-square theme--light')
                    v-icon(v-else class='v-icon notranslate fas fa-check-square theme--light primary--text')
                    span.pl-8 {{$t('FILTERSETTING.NO_FILTER')}}
        v-combobox.no-border(prepend-icon="fa-filter", :label="$t('ACTIVITY.PRIORITY')" v-model='activityPriority' placeholder-color="#f0f", :placeholder="activityPriority.length == 0? $t('FILTERSETTING.NO_FILTER'): ''" :items='activityPriorityList' item-value='id' item-text='name' index='0' height='36' flat @change='' multiple)
            template(v-slot:prepend-item)
                v-list-item(style='margin-left:2px; font-size: 0.8125rem;' border-0 @click="activityPriority = []")
                    v-icon(v-if='activityPriority.length > 0' class='v-icon notranslate far fa-square theme--light')
                    v-icon(v-else class='v-icon notranslate fas fa-check-square theme--light primary--text')
                    span.pl-8 {{$t('FILTERSETTING.NO_FILTER')}}
        v-combobox.no-border(:label="$t('ACTIVITY.TYPE')" prepend-icon="fa-filter" v-model='activitySelectType' :placeholder="activitySelectType.length == 0? $t('FILTERSETTING.NO_FILTER'): ''" :items='activityTypeList' item-value='id' item-text='name' index='0' height='36' flat @change='' multiple)
            template(v-slot:prepend-item)
                v-list-item(style='' border-0 @click="activitySelectType = []")
                    v-icon(v-if='activitySelectType.length > 0' class='v-icon notranslate far fa-square theme--light')
                    v-icon(v-else class='v-icon notranslate fas fa-check-square theme--light primary--text')
                    v-list-item-group.ml-3(class='inline-edit-cell')
                        v-list-item-avatar.d-inline-block(color='#9E9E9E', size="25")
                            v-icon(size="18" color='white') mdi-bookmark
                    span {{$t('FILTERSETTING.NO_FILTER')}}
            template(v-slot:item='{ item }')
                v-icon(v-if='!activitySelectType.includes(item)' class='v-icon notranslate far fa-square theme--light')
                v-icon(v-else class='v-icon notranslate fas fa-check-square theme--light primary--text')
                v-list-item-group.ml-3(class='inline-edit-cell')
                    v-list-item-avatar.d-inline-block(:color='item.color', size="25")
                        v-icon(size="18" color='white') {{ item.icon }}
                .t-black {{ item.name }}
        v-combobox.no-border(prepend-icon="fa-filter", :label="$t('ACTIVITY.COMPLETED')" v-model='activityCompleted' :items='activityCompletedList' item-value='key' item-text='name' index='0' height='36' flat @change='')
        v-select.no-border(v-if='activity_calendar == ""' :label="$t('ACTIVITY.TIME_FRAME')" v-model='activityTimeFrame' :items='activityDateMode' item-value='index' item-text='name' prepend-icon='icon-calendar' height='36' flat @change='')
        v-row(no-gutters v-if='activityTimeFrame === 5 && activity_calendar==""')
            v-col(cols='2')
            v-col(cols='3')
                v-menu(ref="menuStart" v-model="startDayMenu" :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field(v-model='startDay' solo dense flat v-bind="attrs" v-on="on" readonly :change='toDateCheck()')
                    v-date-picker(v-model='startDay' @input='startDayMenu = false' no-title scrollable @change='') 
            v-col.pl-1.d-flex.align-center.justify-center(cols='2')
                p － 
            v-col(cols='3')
                v-menu(ref="menuEnd" v-model="endDayMenu" :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field(v-model='endDay' solo dense flat v-bind="attrs" v-on="on" readonly)
                    v-date-picker(v-model='endDay' @input='endDayMenu = false' no-title scrollable @change='' :min='minDate()') 
    //- 
    v-row.mt-2
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="primary",
          :disabled='exporting || !fisrt_loading',
        ) {{exporting ? $t('IMPORT_EXPORT.ING') : $t('IMPORT_EXPORT.DOWNLOAD')}}
          v-icon(size='16' color='black' :disabled='true' v-if='exporting') fas fa-circle-notch fa-spin
  v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
  v-dialog(v-model='editFilterDialog' width='800' content-class='statusDialog' v-if='editFilterDialog' scrollable persistent)
      edit-filter-dialog(@emitEditFilterDialog='onEmitEditFilter' :custom-filter-data ='editData' :class-name='page' :stage-type='stage_type' :stage-type-list='stageTypeList' :customField='CFList' editMode='EDIT')
</template>

<script>
import Vue from "vue";
import ResourceDataService from "@/services/ResourceDataService";
import CustomFilterDataService from "@/services/CustomFilterDataService";
import CustomFieldDataService from "@/services/CustomFieldDataService";
import OrganizationDataService  from "@/services/OrganizationDataService";
import pageList from "@/array/the_pageList";
import messageDialog from '@/components/Dialog/redirectDialog';
import i18n from '/common/plugins/vue-i18n.js' 
import StageDataService from "@/services/StageDataService"
import errorDialog from "@/components/Dialog/errorDialog";
import editFilterDialog from "@/components/Dialog/Setting/editFilterDialog";
import ActivityDataService from '@/services/ActivityDataService';

// Activity
import activityPriorityList from "@/array/the_activityPriorityList";
import activityCompletedList from "@/array/the_activityCompletedList";


export default Vue.extend({
      props: {    
        page_name: {
            type: String,
            required: true,
        },
        stage_type: {
            required: false,
        },
        search: {
            type: String,
            required: false,
        },
        filter_id: {
            required: false,
        },
        tab: {
            required: false,
        },
        owner: {
            required: false,
        },
        export_activity: {
            required: false,
        },
    },
    components: {
        messageDialog,
        errorDialog,
        editFilterDialog,
    },
    data() {
        return {
            opptyProduct:false,
            page_tab:null,
            first_page:null,
            editFilterDialog:false,
            errorDialog:false,
            errorMessage:'',
            dataType:'db',
            page:null,
            stage:'',
            stageCaseList:[],
            stageOpptyList:[],
            stageTypeList:[],
            filter_list : [],
            all_filter_list : [{"id":0,"name":i18n.t('FILTERSETTING.NO_FILTER')}],
            filter:0,
            filter_data:null,
            pageList: pageList,
            typeList:[
                { key:'temp', name: i18n.t('IMPORT_EXPORT.TEMP')},
                { key:'db', name: i18n.t('IMPORT_EXPORT.DB_DATA')}
            ],
            messageDialog:false,
            message:"",
            valid: true,
            isLoading: false,
            formRule: {
                fieldRules: [
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                ],
            },
            user: JSON.parse(window.localStorage.getItem('user')),
            allCustomField: [],
            CFList: null,
            search_str:'',
            fisrt_loading:false,
            ownerCaseList:[{
                id: 0,
                full_name: i18n.t('GENERAL.ALL')
            }],
            ownerOpptyList:[{
                id: 0,
                full_name: i18n.t('GENERAL.ALL')
            }],
            ownerList: [
                {
                    id: 0,
                    full_name: i18n.t('GENERAL.ALL')
                }
            ],
            selectOwner: {},
            exporting:false,

            // Activity
            startDayMenu: false,
            startDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            endDayMenu: false,
            endDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            activityTimeFrame: 0,
            activityDateMode: [
                { index: 0, name: i18n.t('FILTERSETTING.NO_FILTER') },
                { index: 1, name: i18n.t('DASHBOARD.THIS_YEAR') },
                { index: 2, name: i18n.t('DASHBOARD.THIS_SEASON') },
                { index: 3, name: i18n.t('DASHBOARD.THIS_MONTH') },
                { index: 4, name: i18n.t('DASHBOARD.THIS_WEEK') },
                { index: 5, name: i18n.t('DASHBOARD.SELECT_DATE') },
            ],
            activityPriority: [],
            activityCompleted: null,
            activitySelectType:[],
            activitySelectOwner: [],
            activityTypeList: [],
            activityOwnerList:[],
            activityCompletedList: [{
                key: 0,
                name: i18n.t('FILTERSETTING.NO_FILTER')
            }
            ].concat(activityCompletedList),
            activityPriorityList: activityPriorityList,
            activity_calendar:'',
        };
    },
    async created(){
        this.page = this.page_name;
        if(this.page=="Opportunity" || this.page=="Case"){
            this.first_page = this.page;
        }
        
        if(this.stage_type){
            this.stage = this.stage_type.id;
        }
        if(this.search)
        {
            this.search_str = this.search;
        }
        // get activity user
        let isAdmin = false;
        let userJson = window.localStorage.getItem('user');
        let currentUser = JSON.parse(userJson);
        currentUser.is_staff ? isAdmin = true : isAdmin = false;
        if(!isAdmin){
            this.pageList = this.pageList.filter(el => el.key == "Activity");
        }
        await OrganizationDataService.getowner()
        .then(response => {
            response.data.forEach(item => {
                if (item.first_name != null) {
                    item.full_name = item.last_name + " " + item.first_name;
                } else {
                    item.full_name = item.last_name;
                }
                if(isAdmin || currentUser.userid == item.id){
                    this.activityOwnerList.push(item);
                }
                // this.ownerList_inlineEdit.push(item);
            });
        })
        .catch(response => {
            this.showErrorDialog(response);
        })
        await ActivityDataService.getActivityType()
        .then(response => {
            this.activityTypeList = response.data;
        })
        .then(()=>{
            this.activitySelectType = [];
            this.activityCompleted = this.activityCompletedList[0];
        })
        .catch(response => {
            console.log(response);
        })
        if(this.export_activity){
            this.activityPriority = this.export_activity.activityPriority;
            this.activityCompleted = this.export_activity.activityCompleted;
            this.activitySelectType = this.export_activity.activitySelectType;
            this.activitySelectOwner = this.export_activity.activitySelectOwner;
            this.activity_calendar = this.export_activity.calendar;
            this.activityTimeFrame = this.export_activity.activityTimeFrame;
            this.startDay = this.export_activity.startDay;
            this.endDay = this.export_activity.endDay;
        }

        await OrganizationDataService.getchangeowner('cases')
        .then(response => {
            response.data.forEach(item => {
                if (item.first_name != null) {
                    item.full_name = item.last_name + " " + item.first_name;
                } 
                else {
                    item.full_name = item.last_name;
                }
                this.ownerCaseList.push(item);
            });
            this.selectOwner = this.owner;
        })
        .catch(response => {
            this.showErrorDialog(response)
        })

        await OrganizationDataService.getchangeowner('opportunities')
        .then(response => {
            response.data.forEach(item => {
                if (item.first_name != null) {
                    item.full_name = item.last_name + " " + item.first_name;
                } 
                else {
                    item.full_name = item.last_name;
                }
                this.ownerOpptyList.push(item);
            });
            this.selectOwner = this.owner;
        })
        .catch(response => {
            this.showErrorDialog(response)
        })

        await StageDataService.stageList('case')
        .then(response => {
            this.stageCaseList = response.data;
            if(this.stageCaseList.length == 0)
            {
                let item = {};
                item.stage_type_name = i18n.t('CASE.NO_CASE_STAGE');
                this.stageTypeList.push(item);
            }
            else{
                if(this.page_name == 'Case')
                    this.stageTypeList = this.stageCaseList;
            }
        })
        .catch(response => {
            this.showErrorDialog(response);
                })

        await StageDataService.stageList("deal")
        .then(response => {
            if (response.data.length != 0) {	
                response.data.forEach(item => {
                    item.stagedef_set = item.stagedef_set.sort(function(a, b){ 
                        return a.sequence_number-b.sequence_number
                    });
                    this.stageOpptyList.push(item);
                });
                if(this.page_name == 'Opportunity')
                    this.stageTypeList = this.stageOpptyList;
            } 
            else {
                let item = {}
                item.stage_type_name = i18n.t('DEAL.NO_DEAL_STAGE');
                this.stageOpptyList.push(item);
            }
        })
        .catch(response => {
            this.showErrorDialog(response);
        });
        if(this.stageOpptyList.length==0)
        {
            this.pageList = this.pageList.filter(e=>e.key == "Opportunity");
        }
        if(this.stageCaseList.length==0)
        {
            this.pageList = this.pageList.filter(e=>e.key == "Case");
        }
        
        await CustomFilterDataService.list()
        .then((response)=>{
            this.all_filter_list = this.all_filter_list.concat(response.data);
            this.getfilterList();
        })
        if(this.filter_id){
            this.filter = this.filter_id;
        }
        this.fisrt_loading = true;
    },
    methods: {
        minDate(){
            let current_date = new Date(this.startDay).getTime();
            let min = current_date;
            return (new Date(min).getFullYear()).toString() +'-' +(new Date(min).getMonth()+1).toString().padStart(2,0)+'-'+ (new Date(min).getDate()).toString().padStart(2,0)
        },
        toDateCheck(){
            if(this.startDay){
                let FD = new Date(this.startDay).getTime();
                let TD = new Date(this.endDay).getTime();
                if(this.endDay && (TD-FD)<=0){
                    this.endDay = this.minDate(this.startDay);
                }
            }
        },
        validateForm() {
            if (this.$refs.form.validate()) 
                return true;
            else 
                return false;
        },
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
        getfilterList(){
            if(this.page == 'Opportunity' || this.page == 'Case'){
                this.filter_list = this.all_filter_list.filter(el => el.class_name == this.page && el.stage_type_id == this.stage || el.id == 0 || el.class_name == this.page && el.stage_type_id == 'all')
            }else{
                this.filter_list = this.all_filter_list.filter(el => el.class_name == this.page || el.id == 0)
            }
        },
        async onConfirm() {
        let activity_data = null;
        if(this.page=="Activity"){
            let selectPriority = [];
            this.activityPriority.map((item)=>{
                selectPriority.push(item.key);
            })
            if(selectPriority.includes(0)){
                selectPriority = [];
            }
            let selectCompleted = this.activityCompleted.key == 0 ? '' : this.activityCompleted.key;
            let selectOwner = [];
            this.activitySelectOwner.map((item)=>{
                selectOwner.push(item.id);
            })
            if(selectOwner.includes(0) || selectOwner.length == 0 ){
                selectOwner = [0];
            }
            let selectTimeFrame = this.activityTimeFrame == 0 ? '' : this.activityTimeFrame;
            let startDay = this.activityTimeFrame == 5 ? this.startDay : '';
            let endDay = this.activityTimeFrame == 5 ? this.endDay : '';
            let selectType = [];
            this.activitySelectType.map((item)=>{
                selectType.push(item.id);
            })
            if(selectType.includes(0)){
                selectType = [];
            }
            activity_data = {
                "priority": selectPriority,
                "completed" : selectCompleted,
                "date_range" :selectTimeFrame,
                "activity_type": selectType,
                "owner" : selectOwner,
                "startDay" : startDay,
                "endDay" : endDay,
                "calendar" : this.export_activity? this.export_activity.calendar:''
            }
        }
        if (this.validateForm()) {
            this.exporting = true
            let stage_type;
            let filter = this.filter;
            if(this.page == 'Case' || this.page == 'Opportunity')
            {
                if (typeof(this.stage) != "object")
                {
                    stage_type = this.stage;
                }
            else
                {
                    stage_type = this.stage.id;
                }
            }
            else{
                stage_type = null
            }

            if(this.page!= 'KnowledgeArticle' && filter == 0){
                filter = ''
            }
            if(this.tab && this.first_page && this.first_page == this.page){
                this.page_tab = this.tab;
            }
            await ResourceDataService.export(this.page,this.dataType,stage_type,filter,this.search_str,this.page_tab,this.selectOwner,activity_data,this.opptyProduct)
            .then(response => {
                if ('download' in document.createElement('a')) {
                let link = document.createElement('a')
                let now = new Date()
                let yyyy = now.getFullYear()
                let MM = (now.getMonth()+1).toString().padStart(2,'0')
                let dd = now.getDate().toString().padStart(2,'0')
                let hh = now.getHours().toString().padStart(2,'0')
                let mm = now.getMinutes().toString().padStart(2,'0')
                let ss = now.getSeconds().toString().padStart(2,'0')
                let currentTime = `${yyyy}-${MM}-${dd} ${hh}${mm}${ss}`
                let export_type = this.dataType=='temp'? '[Template]':''
                let stage_name = ''
                if(this.stage){
                    stage_name = this.stageTypeList.filter(el=>el.id==this.stage)[0].stage_type_name
                }
                link.style.display = 'none'
                link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(response.data)
                link.download = `${this.page} ${stage_name} ${export_type} ${currentTime}.csv`
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } else {
                this.errorMessage = i18n.t('IMPORT_EXPORT.ERROR')
                this.errorDialog = true;
            }
                this.$emit("emitExport");
                this.exporting = false;
            });
        }
        },
        onCancel() {
            this.$emit("emitExport");
        },
        showMessageDialog() {
        },
        async showEditFilterDialog(item){
            if(this.allCustomField.length == 0){
            await CustomFieldDataService.getCustomFieldDetail("Setting")
            .then((response) => {
                this.allCustomField = response.data;
            })
            .catch((response) => {
                this.showErrorDialog(response);
            });
            }
            await (this.getCustomField())
            this.editMode = 'EDIT';
            this.editData = item;
            this.editFilterDialog = true;
        },
        haveAuth(item){
            if(item != null && item.id != ""){
                let owner_is_user = item.owner.id == this.user.userid;
                let owner_is_admin = this.user.is_staff;
                if(owner_is_admin){
                    return false;
                }
                else{
                    if(owner_is_user){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
            }else{
                return true;
            }
        },
        canEdit_filter(item){
            if(item == null){
                return false;
            }
            let is_seed_data = item.owner == null ? true : false;
            let is_created_by_this_user = item.owner != null ? item.owner.id == this.user.userid : false;
            let this_user_is_admin = this.user.is_staff;
            let is_NO_FILTER = item.id=='';

            if(is_NO_FILTER){
                return false;
            }
            else if(is_seed_data){
                if(this_user_is_admin){
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                if(this_user_is_admin){
                    return true;
                }else{
                    if(is_created_by_this_user){
                        return true;
                    }else{
                        return false;
                    }
                }
            }
        },
        getCustomField(){
        if(this.page == "Contact" || this.page == "Organization"){
            this.CFList = this.allCustomField.find(e => e.class_name == this.page).definition;
        }else{
            let def = this.allCustomField.find(e => e.class_name == this.page).definition;
            if(def != null){
                try{
                    this.CFList = def.find(i => i.stage_type_id == this.stage).data;
                }
                catch{
                    this.CFList = null;
                }
            }
        }
        },
        onEmitEditFilter(confirmEdit, orignData, editedData){
            if(confirmEdit){
                if(editedData){
                    this.$emit('emitEditFilter', editedData);
                }
            }
            else if(!confirmEdit){
                if(orignData != undefined && editedData != undefined){
                    for(let prop in this.editData){
                        let p = prop.toString();
                        this.$set(this.editData, p, orignData[prop]) 
                    }
                }
            }
            this.editFilterDialog = false;
        },
      },
      watch:{
        "page":function () {
            if(this.page !="Case" && this.page !="Opportunity")
            {
                this.stage = null
            }
            if(this.page == "Case")
            {
                this.stageTypeList = this.stageCaseList
                if(this.stage_type && this.page_name == this.page)
                {
                    this.stage = this.stage_type.id;
                }else{
                    this.stage = this.stageCaseList[0]['id'];
                    this.page_tab = "list"
                }
            }
            if(this.page == "Opportunity")
            {
                this.stageTypeList = this.stageOpptyList
                if(this.stage_type && this.page_name == this.page)
                {
                    this.stage = this.stage_type.id;
                }
                else{
                    this.stage = this.stageOpptyList[0]['id'];
                    this.page_tab = "list"
                }
            }
            this.getfilterList()
            if(this.fisrt_loading){
                this.filter = 0
                this.selectOwner = 0
            }
        },
        "stage":function () {
            if(this.fisrt_loading){
                this.filter_list = this.all_filter_list.filter(el => el.class_name == this.page && el.stage_type_id == this.stage || el.id == 0 || el.class_name == this.page && el.stage_type_id == 'all')
                this.filter = 0
            }
        },
        "filter": function (){
            
            if(this.filter != 0){
                this.filter_data = this.filter_list.filter(el => el.id == this.filter)[0]
            }else{
                this.filter_data = null
            }
        },
      }
});
</script>