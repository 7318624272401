<template lang='pug'>
    v-row( class='item-center')
        v-col( cols='12' class='item-center')
            v-sheet(rounded elevation="2" class='item-center' width='50%') 
                h5.ma-0.t-black {{$t(msg)}}
</template>
<script>

export default {
    props: {
        msg: {
            type: String,
            required: true
        },
    }
}
</script>
<style lang="scss" scoped>
    .item-center{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .row{
        width: 100vw;
        height: 100vh;
    }

    .v-sheet{
        min-height: 100px;
    }
</style>